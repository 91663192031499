<template>
  <b-sidebar
    id="add-new-region-sidebar"
    :visible="isAddNewRegionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-region-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ isDelete ? 'Delete Region Info' :localRegion.id > 0 ? 'Update Region ' : 'Add New Region' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="RegionName"
            rules="required"
          >
            <b-form-group
              label="Region Name"
              label-for="Region"
            >
              <b-form-input
                id="Region"
                v-model="localRegion.name"
                autofocus
                trim
                placeholder="Region Name"
                :readonly="isDelete"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="isActive"
          >
            <b-form-group
              label="isActive"
              label-for="Region"
            >
              <b-form-checkbox
                id="isActive"
                v-model="localRegion.isActive"
                :disabled="isDelete"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-show="!isDelete"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ localRegion.id > 0 ? 'Update' : 'Add' }}
            </b-button>
            <b-button
              v-show="isDelete"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              variant="outline-danger"
              @click="deleteRegion"
            >
              Delete
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRegionSidebarActive',
    event: 'update:is-add-new-region-sidebar-active',
  },
  props: {
    isAddNewRegionSidebarActive: {
      type: Boolean,
      required: true,
    },
    activeRegion: null,
    isDelete: null,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      localRegion: {
        id: null,
        name: '',
        isActive: false,
      },
    }
  },
  watch: {
    activeRegion(newVal, oldVal) {
      if (newVal) {
        this.localRegion = newVal
      }
    },
  },
  methods: {
    resetForm() {
      this.localRegion = {
        id: 0,
        name: '',
        isActive: false,
      }
      // this.activeRegion = null
      this.$emit('refetch-data')
      this.$emit('update:is-add-new-client-sidebar-active', false)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    submitForm() {
      console.log(this.localRegion)
      if (this.localRegion.id > 0) {
        this.UpdateForm()
      } else {
        this.SaveForm()
      }
    },
    SaveForm() {
      console.log(this.localRegion)
      store.dispatch('apps-organisation-region/addRegion', this.localRegion)
        .then(() => {
          this.notification()
          this.isAddNewRegionSidebarActive = false
        })
    },
    UpdateForm() {
      store.dispatch('apps-organisation-region/updateRegion', this.localRegion)
        .then(() => {
          this.notification()
          this.isAddNewRegionSidebarActive = false
        })
    },
    deleteRegion() {
      if (this.localRegion.id <= 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      store.dispatch('apps-organisation-region/deleteRegion', this.localRegion.id)
        .then(response => {
          const result = response.data.success
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          this.resetForm()
          this.notification()
          this.isAddNewRegionSidebarActive = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.isLoading = false
          this.isUpdated = false
        })
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Completed',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-region-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
