import axios from '@axios'

export const GET_REGION = 'getRegion'
export const SET_REGION = 'setRegion'

export default {
  namespaced: true,
  state: {
    region: {},
  },
  getters: {
    getRegion(state) {
      return state.region
    },
  },
  mutations: {
    [SET_REGION](state, val) {
      state.region = val
    },
  },
  actions: {
    fetchAllRegions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Region')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchRegion(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`pamis/admin/organization/Region/GetByID?id=${id}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    addRegion(ctx, RegionFormData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/organization/Region/Create', RegionFormData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    updateRegion(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/pamis/admin/organization/Region/Update?id=${params.id}`, params)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    deleteRegion(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/pamis/admin/organization/Region/Delete?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
